body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#title {
  font-family: 'Oswald', sans-serif;
  font-size: 64px;
  margin-top: 30px;
}

#card-container {
  margin-top: 30px;
  background-color: white;
  max-width: 600px;
  width: 90%;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
}

#card-picture {
  padding-top: 25px;
  padding-bottom: 25px;
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding-right: 20px;
}

#card-img {
  width: clamp(75px, 10vw, 120px);
  height: clamp(75px, 10vw, 120px);
  background-image: url("circle-cropped.png");
  background-size: cover;
}

#card-text {
  padding-left: 15px;
  text-align: left;
  max-width: 70%;
  font-size: clamp(16px, 2.2vw, 20px);
  margin-right: 10px;
}

#services-container {
  margin-top: 50px;
  background-color: white;
  max-width: 600px;
  width: 90%;
  border-radius: 12px;
}

#services-headline {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
  font-family: 'Oswald', sans-serif;
  color: black;
  /*width: 90%;*/
  border-bottom: 1px solid lightgray;
  font-size: clamp(22px, 2.2vw, 26px);
}

#services-checklist {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: clamp(14px, 2.2vw, 16px);
  color: black;
}

.checklist-item {
  padding-left: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#services-checklist .checklist-item:last-of-type {
  margin-bottom: 0;
}

.checklist-checkmark {
  margin-right: 15px;
}

.checklist-text {
  line-height: 22px;
  text-align: left;
  max-width: 500px;
  margin-right: 20px;
}

#contact-container {
  margin-top: 50px;
  margin-bottom: 100px;
  background-color: white;
  max-width: 600px;
  width: 90%;
  border-radius: 12px;
  font-size: 16px;
}

#contact-headline {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
  font-family: 'Oswald', sans-serif;
  color: black;
  border-bottom: 1px solid lightgray;
  font-size: clamp(22px, 2.2vw, 26px);

}

#contact-list {
  margin-top: 20px;
  margin-bottom: 30px;
  line-height: 22px;
  text-align: left;
  color: black;
  font-size: clamp(14px, 2.2vw, 16px);
}

.contact-item {
  padding-left: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.contact-logo {
  width: 24px;
  margin-right: 15px;
}

#contact-list a, a:hover, a:focus, a:active {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  max-width: 170px;
}

#footer-container {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: clamp(12px, 2.2vw, 14px);
  font-family: 'Oswald', sans-serif;
  color: black;
}

#copyright-info {
  margin-right: 5px;
}

#copyright-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}